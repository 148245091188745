import facebook from '../icons/facebook.png'
import instagram from '../icons/instagram.png'
import youtube from '../icons/youtube.png'
import mail from '../icons/mail.png'

const Footer = () => (
	<footer className='flex flex-col gap-5 py-10 sm:flex-row-reverse sm:items-center sm:justify-between sm:px-5 md:px-32 xl:px-60 2xl:px-72'>
		<div className='flex gap-2 justify-center xl:gap-5'>
			{/* socials */}
			<a href='https://www.youtube.com/channel/UCt2yMTq9vx2Z4jm7j3XWTGg'>
				<img className='w-15' src={youtube} alt='Youtube' />
			</a>
			<a href='https://www.facebook.com/amicitiachorus/'>
				<img className='w-15' src={facebook} alt='Facebook' />
			</a>
			<a href='https://www.instagram.com/amicitiachorus/'>
				<img className='w-15' src={instagram} alt='Instagram' />
			</a>
			<a href='mailto:amicitiachorus@gmail.com'>
				<img className='w-15' src={mail} alt='Mail' />
			</a>
		</div>
		<div className='text-center'>Copyright © Amicitia 2022</div>
	</footer>
)

export default Footer
