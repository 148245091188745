import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Bio from '../components/bio'

import conductor from '../images/about/conductor.JPG'
import choir from '../images/about/coro_misericordia.png'

const About = () => {
	return (
		<>
			<Helmet>
				<title>Amicitia Chorus | Sobre nós</title>
			</Helmet>

			<Navbar white={false} selected='/sobre-nos' />
			<div className='mt-28 text-justify'>
				{/* general information */}
				<h1 className='font-cursive my-3 text-center'>Sobre nós</h1>
				<div className='grid-cols-2 xl:grid xl:px-24 xl:py-24'>
					<div className='px-5 space-y-3 md:px-24 xl:pl-0 xl:pr-24 xl:w-100 xl:flex xl:flex-col xl:justify-center'>
						{/* text */}
						<p>
							O coro <span className='font-bold text-main'>Amicitia</span> surge da união de um grupo de jovens que,
							movidos pela amizade e pela música têm como objetivo criar momentos inesquecíveis com base em música coral
							e coral litúrgica. Visando com especial foco a música coral litúrgica, que consideram pouco desenvolvida
							no meio português, iniciaram a sua atividade no final de 2018.
						</p>
						<p>
							Levar a música para um patamar superior é, também, o objetivo do seu maestro e diretor artístico Ricardo
							Leão, que investe no trabalho de obras litúrgicas cuidadosamente selecionadas, para garantir uma elevada
							qualidade musical em todos eventos que participam.
						</p>
						<p>
							Com o objetivo de realizar, também, concertos de música sacara ou profana, este coro também trabalha com
							repertórios diversificados e com o obras de todos os períodos da história e de compositores de todo o
							mundo.
						</p>
						<p>
							O coro <span className='font-bold text-main'>Amicitia</span> assume-se, portanto, como um dos meios de
							difusão da música portuguesa com o objetivo de elevar a qualidade da tradição musical litúrgica portuguesa
							a um nível profissional.
						</p>
					</div>
					<div className='xl:flex'>
						<img className='bg-cover drop-shadow-lg hidden rounded xl:block' src={choir} alt='choir' />
					</div>
				</div>
				<div className='mt-10'>
					{/* BIOS */}
					<Bio title='Ricardo Leão, Diretor Artístico' img={conductor}>
						<p>
							Iniciou os seus estudos aos 11 anos na Banda Musical de Lagares sob a orientação do Professor Alberto
							Vieira, vindo mais tarde a ingressar na Academia de Música de Costa Cabral na classe de Percussão dos
							professores Pedro Góis, André Dias e Marcelo Aires.
						</p>
						<p>
							No âmbito de instrumentista, teve a oportunidade de tomar parte em masterclasses orientados por
							professores como Emanuel Sejourné e Miquel Bernat, e também colaborar com a Orquestra Académica
							Portuguesa, entre outras, pertencendo, atualmente, à orquestra Alord.
						</p>
						<p>
							Por sua vez, no que respeita o âmbito de Direção, refinou conhecimentos com Maestros de notoriedade
							internacional como Jan Cober, Alex Schilings, José Pascual Villaplana, Rafa Agulló Albors, entre outros,
							estudando, em simultâneo, na Academia Europeia de Direção de Banda.
						</p>
						<p>
							Para além de uma ávida atividade artística enquanto membro da Banda do Exército - Destacamento do Porto e
							outras formações - como BICHO e Gaitas Sirigaitas - pratica uma também diligente atividade letiva em
							agrupamentos como a Banda de Paço de Sousa.
						</p>
						<p>
							Para além de ser Maestro Titular e Diretor Artístico do Amicitia Chorus, dirige também a Banda Filarmónica
							de Ribafeita.
						</p>
					</Bio>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default About
