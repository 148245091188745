import Navbar from '../components/navbar'
import Footer from '../components/footer'

import '../styles/pages/home.css'

const Home = () => (
	<div className='h-screen'>
		<div className='absolute top-0 w-full'>
			<Navbar white={true} />
		</div>
		<div className='c-background flex flex-col h-3/4 justify-center text-center text-white sm:h-4/5 md:h-5/6'>
			{/* landing page */}
			<div className='font-cursive text-xl xl:text-3xl'>Unidos pela amizade e pela música</div>
			<div className='font-extrabold text-5xl lg:text-7xl xl:text-8xl'>BEM-VINDO</div>
		</div>
		<Footer />
	</div>
)

export default Home
