import logoWhite from '../images/logo-white.png'
import logo from '../images/logo.png'
import menuWhite from '../icons/menu-white.png'
import menu from '../icons/menu.png'
import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = (props) => {
	const [showMenu, setShowMenu] = React.useState(false)
	const onClick = () => setShowMenu(!showMenu)
	return (
		<div
			className={`${
				props.white ? null : 'backdrop-blur-lg bg-white/90'
			} fixed flex items-center px-8 py-5 top-0 w-full z-10 sm:px-5 md:px-32 xl:px-60 2xl:px-72`}>
			{/* navbar */}
			<Link to='/'>
				{/* logo */}
				{props.white ? (
					<img className='w-48' src={logoWhite} alt='logo' />
				) : (
					<img className='w-48' src={logo} alt='logo' />
				)}
			</Link>
			<div className='ml-auto'>
				{/* right side */}
				<div className='lg:hidden'>
					{/* menu button */}
					<img className='w-7' src={props.white ? menuWhite : menu} alt='menu icon' onClick={onClick} />
				</div>
				<div className='hidden lg:block'>
					{/* horizontal options */}
					<Options selected={props.selected} white={props.white} />
				</div>
			</div>
			<Menu selected={props.selected} white={props.white} show={showMenu} onClick={onClick} />
		</div>
	)
}

const Menu = (props) => {
	return (
		<div
			className={`${
				props.show ? 'opacity-100 visible block' : 'opacity-0 invisible absolute -left-96'
			} transition-all`}>
			<Options selected={props.selected} white={props.white} onClick={props.onClick} />
		</div>
	)
}

const Options = (props) => (
	<div
		onClick={props.onClick}
		className={`${
			props.white ? 'bg-black' : 'bg-white'
		} fixed flex items-center justify-center h-screen w-screen top-0 left-0 z-50 lg:bg-transparent lg:h-auto lg:static lg:w-auto`}>
		{/* navbar menu */}
		<ul
			className={`${
				props.white ? 'text-white' : 'text-black'
			} flex flex-col font-bold gap-7 text-2xl text-center z-50 lg:flex-row lg:font-normal lg:text-base`}>
			<li className='w-100'>
				<Link className={`${props.selected === '/' ? 'text-main' : null} transition-all hover:text-main`} to='/'>
					INÍCIO
				</Link>
			</li>
			<li className='w-100'>
				<Link
					className={`${props.selected === '/sobre-nos' ? 'text-main' : null} transition-all hover:text-main`}
					to='/sobre-nos'>
					SOBRE NÓS
				</Link>
			</li>
			{/* <li className='w-100'>
				<Link
					to='/repertorio'
					className={`${props.selected === '/repertorio' ? 'text-main' : null} transition-all hover:text-main`}>
					REPERTÓRIO CASAMENTOS
				</Link>
			</li> */}
			<li className='w-100'>
				<Link
					className={`${props.selected === '/videos' ? 'text-main' : null} transition-all hover:text-main`}
					to='/videos'>
					VÍDEOS
				</Link>
			</li>
		</ul>
	</div>
)

export default Navbar
