import ReactPlayer from 'react-player'
import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'

const Video = (props) => (
	<div className='border flex-grow max-w-full rounded-xl md:max-w-md'>
		{/* video card */}
		<h3 className='text-center my-5'>
			{props.title}{' '}
			<span className='font-thin'>
				{props.author ? 'de ' : null}
				{props.author}
			</span>
		</h3>
		<div className='space-y-7'>
			<ReactPlayer light={true} style={{ maxWidth: '100%' }} url={props.url} controls={true} pip={true} />
		</div>
	</div>
)

const Videos = () => {
	return (
		<div className=''>
			<Helmet>
				<title>Amicitia Chorus | Vídeos</title>
			</Helmet>

			<Navbar white={false} selected='/videos' />
			<div className='mt-28 px-10'>
				<h1 className='font-cursive text-5xl text-center'>Vídeos</h1>
				<div className='flex flex-col flex-wrap gap-5 items-center justify-center py-7 w-full md:flex-row'>
					<Video title='Ubi Caritas' author='Ola Gjeilo' url='https://www.youtube.com/watch?v=2apvM0jMkPs' />
					<Video title='Flow Water' author='Damien Kehoe' url='https://www.youtube.com/watch?v=E26PQ3SWhfI' />

					<Video title='My Lord has Come' author='Will Todd' url='https://www.youtube.com/watch?v=cBVSEXHfOww' />
					<Video title='Shosholoza' url='https://www.youtube.com/watch?v=GhTmgddWQ2Y' />
					<Video title='Earth Song' author='Frank Ticheli' url='https://www.youtube.com/watch?v=VfXFP9_C_eY' />
					<Video
						title='Sanctus - Missa Brevis'
						author='Jacob De Haan'
						url='https://www.youtube.com/watch?v=UtFYJkQUW2M'
					/>
					<Video
						title='Benedictus - Missa Brevis'
						author='Jacob De Haan'
						url='https://www.youtube.com/watch?v=1IBOGuxH808'
					/>
					<Video title='Acordai' author='Fernando Lopes Graça' url='https://www.youtube.com/watch?v=OltFFOFKeGI' />
					<Video
						title='Gloria - Missa Brevis'
						author='Jacob De Haan'
						url='https://www.youtube.com/watch?v=GmvjRqXcLL8'
					/>
					<Video
						title='Canção da Vindima'
						author='Fernando Lopes Graça'
						url='https://www.youtube.com/watch?v=oOaduTVygVI'
					/>
					<Video
						title='Hoje à noite quem dorme é o leão'
						author='Pedro Almeida'
						url='https://www.youtube.com/watch?v=44wSn2JOSbI'
					/>
					<Video
						title='Vejam Bem'
						author='Zeca Afonso (arr. Rui Ferreira)'
						url='https://www.youtube.com/watch?v=JiERp4guXcU'
					/>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Videos
