import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import './styles/global.css'
import Home from './pages/home'
import About from './pages/about'
// import Repertoire from './pages/repertoire'
import Videos from './pages/videos'

const App = () => {
	return (
		<BrowserRouter>
			<Route exact path='/' component={Home} />
			<Route exact path='/sobre-nos' component={About} />
			{/* <Route exact path='/repertorio' component={Repertoire} /> */}
			<Route exact path='/videos' component={Videos} />
		</BrowserRouter>
	)
}

export default App
