const Bio = (props) => (
	<div className='min-h-screen relative w-full lg:flex lg:justify-center lg:max-h-screen lg:min-h-fit'>
		<img className='object-cover max-h-full z-0 lg:rounded lg:w-1/2 lg:py-10' src={props.img} alt='bios' />
		<div className='lg:px-10 lg:flex lg:flex-col lg:align-center lg:justify-center lg:min-h-fit'>
			<h2 className='absolute font-cursive p-10 text-left text-4xl text-white top-0 lg:py-0 lg:static lg:text-5xl lg:text-black'>
				{props.title}
			</h2>
			<div className='flex flex-col gap-y-2 mt-5 px-5 top-0'>{props.children}</div>
		</div>
	</div>
)

export default Bio
